<template>
  <main>
    Витрина закрыта
    <!-- <ThisComponent
      text-enter-phone-number="Введи свой номер телефона"
      text-enter-code="Введи код из SMS"
    >
      <template #default>
        <h1>Личный кабинет</h1>
        <p>&laquo;МегаФон Ритейл&raquo;&nbsp;&mdash; 12 лет!</p>
        <hr>
      </template>
      <template #auth-error>
        <div class="alert__heading">Ошибка авторизации</div>
        <p>Если не получается зайти, пиши:<br>
          Поволжский филиал&nbsp;&ndash;&nbsp;<a href="mailto:pv_podarok@megafon-retail.ru">pv_podarok@megafon-retail.ru</a><br>
          Северо-западный филиал&nbsp;&ndash;&nbsp;<a href="mailto:szf_podarok@megafon-retail.ru">szf_podarok@megafon-retail.ru</a><br>
          Уральский филиал&nbsp;&ndash;&nbsp;<a href="mailto:ur_podarok@megafon-retail.ru">ur_podarok@megafon-retail.ru</a><br>
          Кавказский филиал&nbsp;&ndash;&nbsp;<a href="mailto:kv_podarok@megafon-retail.ru">kv_podarok@megafon-retail.ru</a><br>
          Сибирский филиал&nbsp;&ndash;&nbsp;<a href="mailto:sb_podarok@megafon-retail.ru">sb_podarok@megafon-retail.ru</a><br>
          Столичный филиал&nbsp;&ndash;&nbsp;<a href="mailto:stolitsa_podarok@megafon-retail.ru">stolitsa_podarok@megafon-retail.ru</a><br>
          Центральный филиал&nbsp;&ndash;&nbsp;<a href="mailto:cr_podarok@megafon-retail.ru">cr_podarok@megafon-retail.ru</a><br>
          Головной офис&nbsp;&ndash;&nbsp;<a href="mailto:hq_podarok@megafon-retail.ru">hq_podarok@megafon-retail.ru</a>
        </p>
      </template>
    </ThisComponent> -->
  </main>
</template>

<script>
// import ThisComponent from 'base/components/AuthorizationSmsCode'

// ThisComponent.mounted = function () {
//   this.$store.commit('app/updatePageClass', 'page--auth')

//   this.afterMounted()
// }

export default {
  components: {
    // ThisComponent
  }
}
</script>

<style lang="css" scoped>
  main {
    margin: auto auto 0;
  }

  h1 {
    font-size: 1.8em;
    margin-top: 0;
  }

  .form {
    margin-bottom: 20px;
    background: rgba(255, 255, 255, 0.9);
    padding: 20px;
    border-radius: var(--border-radius);
    max-width: 520px;
    box-shadow: 0 0 16px rgba(0, 0, 0, .15);
  }

  .step__fields .form-group {
    margin-bottom: 10px;
  }

  .form-field {
    width: 100%;
  }

  .form .button {
    white-space: nowrap;
  }

  @media (min-width: 480px) {
    .step__fields {
      display: -webkit-flex;
      display: -ms-flex;
      display: flex;
    }

    .step__fields .form-group {
      margin-right: 10px;
      margin-bottom: 0;
    }
  }
</style>
